import { useEffect, useMemo } from 'react';
import { proxy } from 'valtio';

import { type Logger } from '@lp-lib/logger-base';

import { apiService } from '../../services/api-service';
import { type GamePack } from '../../types/game';
import { createProvider } from '../../utils/createProvider';
import {
  markSnapshottable,
  useSnapshot,
  type ValtioSnapshottable,
  ValtioUtils,
} from '../../utils/valtio';
import { useClock } from '../Clock';
import {
  type FirebaseService,
  firebaseService,
  FirebaseValueHandle,
  useIsFirebaseConnected,
} from '../Firebase';
import { log } from './utils';

export type HostedGame = {
  uid: string;
  fullName: string;
  username: string;
  icon: string;
  packName: string;
  packId: string;
  url: string;
  updatedAt: number;
};

export type HostedGameMap = {
  [index: string]: HostedGame;
};

type State = {
  hostedGames: HostedGameMap;
  inited: boolean;
  freeGames: GamePack[];
};

class ZoomLobbyUtils {
  static Path(meetingId: string, kind: 'root' | 'hosted-games'): string {
    if (kind === 'root') return `zoom-lobby/${meetingId}`;
    return `zoom-lobby/${meetingId}/${kind}`;
  }

  static RootHandle(
    svc: FirebaseService,
    meetingId: string
  ): FirebaseValueHandle<State> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.Path(meetingId, 'root'))
    );
  }

  static GamesHandle(
    svc: FirebaseService,
    meetingId: string
  ): FirebaseValueHandle<State['hostedGames']> {
    return new FirebaseValueHandle(
      svc.prefixedSafeRef(this.Path(meetingId, 'hosted-games'))
    );
  }
}

type Dependencies = {
  now: () => number;
  fetchFreeGames: () => Promise<GamePack[]>;
};

class ZoomLobbyAPI {
  private _state = markSnapshottable(proxy<State>(this.initialState()));
  constructor(
    meetingId: string,
    svc: FirebaseService,
    readonly log: Logger,
    readonly deps: Dependencies,
    private gamesHandle = ZoomLobbyUtils.GamesHandle(svc, meetingId)
  ) {}

  get state(): Readonly<ValtioSnapshottable<State>> {
    return this._state;
  }

  async init() {
    const val = await this.gamesHandle.get();
    ValtioUtils.set(this._state, 'hostedGames', val ?? {});
    this.gamesHandle.on((val) => {
      ValtioUtils.set(this._state, 'hostedGames', val ?? {});
    });
    this._state.freeGames = await this.deps.fetchFreeGames();
    this._state.inited = true;
  }

  deinit() {
    this.gamesHandle.off();
  }

  reset() {
    ValtioUtils.reset(this._state, this.initialState());
    this._state.inited = false;
  }

  async addHostedGame(game: Omit<HostedGame, 'updatedAt'>) {
    await this.gamesHandle.ref.child(game.uid).set({
      ...game,
      updatedAt: this.deps.now(),
    });
  }

  async deleteHostedGame(uid: string) {
    await this.gamesHandle.ref.child(uid).remove();
  }

  private initialState(): State {
    return {
      hostedGames: {},
      freeGames: [],
      inited: false,
    };
  }
}

const { Provider, useCreatedContext } =
  createProvider<ZoomLobbyAPI>('ZoomLobby');

export function ZoomLobbyProvider(props: {
  meetingId: string;
  children?: React.ReactNode;
}) {
  const clock = useClock();
  const firebaseConnected = useIsFirebaseConnected();
  const instance = useMemo(
    () =>
      new ZoomLobbyAPI(props.meetingId, firebaseService, log, {
        now: clock.now,
        fetchFreeGames: async () => {
          const resp = await apiService.gamePack.getGamePacksByCollection(
            'zoomFree'
          );
          return resp.data.gamePacks;
        },
      }),
    [clock.now, props.meetingId]
  );

  useEffect(() => {
    if (!firebaseConnected) return;
    instance.init();
    return () => instance.deinit();
  }, [firebaseConnected, instance]);

  return <Provider value={instance}>{props.children}</Provider>;
}

export function useZoomLobbyAPI() {
  return useCreatedContext();
}

export function useHostedGames() {
  const api = useZoomLobbyAPI();
  const hostedGameMap = useSnapshot(api.state).hostedGames;
  return useMemo(() => {
    return Object.values(hostedGameMap).sort(
      (a, b) => b.updatedAt - a.updatedAt
    );
  }, [hostedGameMap]);
}

export function useFreeGames(): GamePack[] {
  const api = useZoomLobbyAPI();
  return useSnapshot(api.state).freeGames as GamePack[];
}

export function useZoomLobbyInited() {
  const api = useZoomLobbyAPI();
  return useSnapshot(api.state).inited;
}
