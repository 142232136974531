import {
  type AnalyticsBrowser,
  type EventProperties,
} from '@segment/analytics-next';

export class ZoomAnalytics {
  constructor(private readonly analytics: AnalyticsBrowser) {}

  trackZoomHostGameClicked(props?: EventProperties) {
    this.analytics.track('Zoom Host Game Clicked', props);
  }

  trackZoomJoinGameClicked(props?: EventProperties) {
    this.analytics.track('Zoom Join Game Clicked', props);
  }
}
